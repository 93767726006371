import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link, FormattedMessage } from "gatsby-plugin-intl"


class PasswordResetLinkExpired extends React.Component {
  render () {
    return (
    <Layout>

      <SEO title="Password Reset Link Expired" />

      <div className="white-container mb-5">


        <div className="text-center mb-4">

          <span className="icon-error fs-40"></span>

        </div>

        <h1 className="text-center"><FormattedMessage id="1_1_3_login_forgot_email_link_expired.title" /></h1>

        <p className="text-center mb-4"><FormattedMessage id="1_1_3_login_forgot_email_link_expired.description" /></p>


        <div className="">
          <Link to="/sessions/login" className="button red mx-auto"><FormattedMessage id="1_1_3_login_forgot_email_link_expired.new_link_button" /></Link>
        </div>

      </div>

    </Layout>
    )
  }
}


export default PasswordResetLinkExpired
